import moment from 'moment'
import api from './base'

export default {
  getAll ({ filters = {} }) {
    const year = filters.year || moment().format('YYYY')
    const month = filters.month || moment().format('MM')
    const dateFrom = moment(`${year}-${month}-01 00:00:00`).startOf('day').format()
    const dateTo = moment(`${year}-${month}-01 00:00:00`).endOf('month').endOf('day').format()

    return api().get(`reports?dateFrom=${encodeURIComponent(dateFrom)}&dateTo=${encodeURIComponent(dateTo)}`)
  },
}
